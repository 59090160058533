/*! Main */

// Variables
var navbar = $('#navbar-main');
distance = navbar.offset().top;
//var article_products = $('.products');
//distance_article_products = article_products.offset().top;
$window = $(window);
windowWidth = $(window).width() - 1;
var dropopen = false;
var dropopen_products = false;
var dropopen_inspiration = false;
var dropopen_education = false;

/** Tablet dropdown background **/
if (windowWidth < 1200) {
    $('.dropdown-bg').addClass('dropdown-bg-tablet');
}


/* DOM Ready */
jQuery(document).ready(function ($) {

    desktopScroll ();
    productCollectionStage ();
    searchDrop(); //Search dropdown
    tabletDropown(); //Tablet dropdowns
    socialFloat(); //Social icon float in mobile menu
    mobileModal(); //Mobile modal
    disableFilters(); //Disable filters Browse all page
    plusMinus(); //Mobile menu plus - minus

    // strip html tags from strings
    //function strip(html) {
    //    var tmp = document.createElement("DIV");
    //    tmp.innerHTML = html;
    //    return tmp.textContent || tmp.innerText || "";
    //}

    // strip sup html element within title attributes and update the attribute
    //$('.main-nav .dropdown [title]').each(function () {
    //    var title = $(this).attr('title');
    //    var titleText = strip(title);
    //    $(this).attr('title', titleText);
    //});

    // forms
    $('.off-form-validate').validate({
        highlight: function (element) {
            $(element).parent().addClass('field-error');
        },
        unhighlight: function (element) {
            $(element).parent().removeClass('field-error');
        }
    });

    $('.get-more').change(function () {
        var radioBtns = $(this).parents('.block-check-btn-wrap').find('.inline-radio-fieldset');
        var label = $(this).parents('.custom-check-btn').find('label');
        if ($(this).is(':checked')) {
            label.addClass('checked');
            radioBtns.removeClass('disabled-radios');
            radioBtns.find('input').attr('disabled', false);
        } else {
            label.removeClass('checked');
            radioBtns.addClass('disabled-radios');
            radioBtns.find('input').attr('disabled', true);
        }
    }).change();

    //Carousels
    //Homepage Product Collection Carousel
    $('.product-collection-thumbs').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
        accessibility: false,
        //dots: true,
        centerMode: false,
        draggable: true,
        focusOnSelect: false,
        //asNavFor: '.product-col-sli',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    focusOnSelect: false
                }
            },
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                    focusOnSelect: true,
                    asNavFor: '.product-col-sli',
                }
            }
        ]
    });

    $('.slick-next, .slick-prev').click(function (e) {
        if ($(this).hasClass('slick-disabled')) {
            //console.log($(this).attr('class'));
            //console.log('end');
            e.preventDefault();
        }
    });

    //Homepage Articles Collection Carousel
    $articleSlick = $('.articles-collection-slider');
    settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
        arrows: true,
        dots: true,
        centerMode: true,
        infinite: true
    }
    if ($(window).width() > 480) {
        $articleSlick.slick(settings);
    }

    if ($(window).width() < 480) {
        if (!$('.product-col-sli').hasClass('slick-initialized')) {
            $('.product-col-sli').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                accessibility: false,
                draggable: true,
                arrows: false,
                dots: true,
                focusOnSelect: true,
                asNavFor: '.product-collection-thumbs'
            });
        }
    }

    // reslick only if it's not slick()
    $(window).on('resize', function () {
       fixBadSidebarBackground();
        if ($(window).width() < 481) {
            if (!$('.product-col-sli').hasClass('slick-initialized')) {
                $('.product-col-sli').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    accessibility: false,
                    draggable: true,
                    arrows: false,
                    dots: true,
                    focusOnSelect: true,
                    asNavFor: '.product-collection-thumbs'
                });
            }
            //return
        } else {
            if ($('.product-col-sli').hasClass('slick-initialized')) {
                $('.product-col-sli').slick('unslick');
            }
            //return
        }

        if ($(window).width() < 481) {
            if ($articleSlick.hasClass('slick-initialized')) {
                $articleSlick.slick('unslick');
            }
            //return
        } else {
            if (!$articleSlick.hasClass('slick-initialized')) {
                $articleSlick.slick(settings);
            }
            //return
        }

    });

    $('.articles-collection-slider .slick-arrow').hover(function () {
        if ($(this).hasClass('slick-next')) {
            $(this).parents('.articles-collection-wrapp').find('.article-collection-arrow-next').toggleClass('article-collection-arrow-hover');
        } else if ($(this).hasClass('slick-prev')) {
            $(this).parents('.articles-collection-wrapp').find('.article-collection-arrow-prev').toggleClass('article-collection-arrow-hover');
        }
    });

    //Products Details Page - Hero Gallery
    $('.hero-gallery-stage').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
        arrows: false,
        dots: false,
        centerMode: false,
        draggable: true,
        //fade: true,
        focusOnSelect: true,
        asNavFor: '.hero-gallery-thumbs',
        responsive: [
            {
                //breakpoint: 993,
                breakpoint: 767,
                settings: {
                    dots: true
                }
            }
        ]
    });
    //Products Details Page - Hero Gallery Thumbs
    $('.hero-gallery-thumbs').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 150,
        accessibility: false,
        arrows: false,
        centerMode: false,
        draggable: true,
        focusOnSelect: true,
        asNavFor: '.hero-gallery-stage',
    });

    //Prevent Clicking On Thumbs While Animating // **Adding/Removing Class in IE9 does not work
    var galleryThumbsWrapp = document.getElementById('hero-gallery-thumbs-wrapp');
    $('.hero-gallery-stage').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        galleryThumbsWrapp.className += ' hero-gallery-ani';
        $('.hero-gallery-thumbs').find('.slick-slide').removeClass('slick-current').not('.slick-cloned').eq(nextSlide).addClass('slick-current'); // Add/Remove Current class on thumbs fix
        //console.log('start-animating');
    });
    $('.hero-gallery-stage').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        galleryThumbsWrapp.className = galleryThumbsWrapp.className.replace(/(?:^|\s)hero-gallery-ani(?!\S)/g, '');
        //console.log('finish-animating');
    });

    // remove 'slider-loading' class on doc ready if it is attached to the slider
    $('.product-collection-slider, .product-collection-thumbs-slider, .articles-collection-slider, .hero-gallery').removeClass('slider-loading-hidden');
    setTimeout(function () {
        $('.product-collection-slider, .product-collection-thumbs-slider, .articles-collection-slider, .hero-gallery').removeClass('slider-loading');
    }, 600);

    //Article slider
    $('.article-slider').slick({
        dots: true
    });

    //Tooltips
    $('.off-tooltip').tooltip();

    //Remove Default Action From Perfect Items Anchor
   $('.perfect-for-item a').on('click', function (e) {
       e.preventDefault();
       $(this).blur();
   });

    // scroll to the top of the opened accordion panel
   $('.accordion-panel-group').on('shown.bs.collapse', function () {
       var panel = $(this).find('.accordion-panel-heading');
       var mainNav = $('.navbar-main').outerHeight();
       $('html, body').animate({
           scrollTop: panel.offset().top - mainNav
       }, 200);
   });

    //Back to top
 
    function backToTop() {
        var scrollTrigger = 100; // px
        var scrollTop = $(window).scrollTop();
        if (scrollTop > scrollTrigger) {
            $('.js-back-to-top').addClass('back-to-visible');
        } else {
            $('.js-back-to-top').removeClass('back-to-visible');
        }
    }

    if ($('.js-back-to-top').length) {
        backToTop();
        $(window).on('scroll', function () {
            backToTop();
        });
        $('.js-back-to-top').on('click', function (e) {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
        });
    }

    // Equalize Element Heights
    _equalize('.js-equalize-height');
    _equalize('.footer-options-title');
    _equalize('.related-item-card');
    _equalize('.compare-item-title');

    // Single Accordion Panel Head - Prevent Default Click Events
    $('.single-panel').click(function (e) {
        e.preventDefault();
        return false;
    });

    //Dropdown Background Animation + Dropdown Content Showing Animation

    //products dropdown
    $(".main-nav .dropdown:nth-child(1)").hover(function(){
        $(this).children('.dropdown-menu').stop(true, false).delay(200).animate({ opacity: '1' }, 800);
        var dropdownHeight = $(this).children('.dropdown-menu').outerHeight() + $('#navbar-main').outerHeight();
        var dropdownHeightFixed = dropdownHeight + 20; // compensate for the fixed nav top position - desktopScroll.js
        if ($window.scrollTop() > distance && $(window).width() > 1200) {
            $('.dropdown-bg').stop(true, false).delay(200).animate({ height: dropdownHeightFixed, opacity: "0.95" }, 200); // was fixed to 550px
        }else {
            $('.dropdown-bg').stop(true, false).delay(200).animate({ height: dropdownHeight, opacity: "0.95" }, 200); // was fixed to 570px
        }
    }, function() {
        $('.dropdown-bg').stop(true, false).animate({ height: "0px", opacity: "0" }, 500);
        $(this).children('.dropdown-menu').stop().animate({ opacity: '0'}, 400);
    });

    //inspiration dropdown
    $(".main-nav .dropdown:nth-child(2)").hover(function(){
        var dropdownHeight = $(this).children('.dropdown-menu').outerHeight() + $('#navbar-main').outerHeight();
        var dropdownHeightFixed = dropdownHeight + 20; // compensate for the fixed nav top position - desktopScroll.js
        $(this).children('.dropdown-menu').stop(true, false).delay(200).animate({ opacity: '1' }, 800);
        if ($window.scrollTop() > distance) {
            $('.dropdown-bg').stop(true, false).delay(200).animate({ height: dropdownHeightFixed, opacity: "0.95" }, 200);
        }else {
            $('.dropdown-bg').stop(true, false).delay(200).animate({ height: dropdownHeight, opacity: "0.95" }, 200);
        }
    }, function() {
        $('.dropdown-bg').stop(true, false).animate({ height: "0px", opacity: "0" }, 500);
        $(this).children('.dropdown-menu').stop().animate({ opacity: '0'}, 400);
    });

    //education dropdown
    $(".main-nav .dropdown:nth-child(3)").hover(function(){
        var dropdownHeight = $(this).children('.dropdown-menu').outerHeight() + $('#navbar-main').outerHeight();
        var dropdownHeightFixed = dropdownHeight + 20; // compensate for the fixed nav top position - desktopScroll.js
        $(this).children('.dropdown-menu').stop(true, false).delay(200).animate({ opacity: '1' }, 800);
        if ($window.scrollTop() > distance) {
            $('.dropdown-bg').stop(true, false).delay(200).animate({ height: dropdownHeightFixed, opacity: "0.95" }, 200);
        }else {
            $('.dropdown-bg').stop(true, false).delay(200).animate({ height: dropdownHeight, opacity: "0.95" }, 200);
        }
    }, function() {
        $('.dropdown-bg').stop(true, false).delay(200).animate({ height: "0px", opacity: "0" }, 500);
        $(this).children('.dropdown-menu').stop().animate({ opacity: '0'}, 400);
    });

   

    //Tablet
    if ($(window).width() > 766 && $(window).width() < 1200) {
        //Dropdown Background Animation + Dropdown Content Showing Animation

        //products dropdown
        $(".main-nav .dropdown:nth-child(1)").on('click',function(){
            var clicked = ($(this).hasClass('open'));
            closeSearch();
            var dropdownHeight = $(this).children('.dropdown-menu').outerHeight() + $('#navbar-main').outerHeight();
            if(clicked) {
                $('.dropdown-bg').css({ height: "0px", opacity: "0" });
            } else {
                $('.dropdown-bg').css({ height: dropdownHeight, opacity: "0.95" }); // was fixed to 550px
            }

        });

        //inspiration dropdown
        $(".main-nav .dropdown:nth-child(2)").on('click', function(){
            var clicked = ($(this).hasClass('open'));
            closeSearch();
            var dropdownHeight = $(this).children('.dropdown-menu').outerHeight() + $('#navbar-main').outerHeight();
            if(clicked) {
                $('.dropdown-bg').css({ height: "0px", opacity: "0" });
            } else{
                $('.dropdown-bg').css({ height: dropdownHeight, opacity: "0.95" });
            }
        });

        //education dropdown
        $(".main-nav .dropdown:nth-child(3)").on('click', function(){
            var clicked = ($(this).hasClass('open'));
            closeSearch();
            var dropdownHeight = $(this).children('.dropdown-menu').outerHeight() + $('#navbar-main').outerHeight();
            if (clicked) {
                $('.dropdown-bg').css({ height: "0px", opacity: "0" });
            } else {
                $('.dropdown-bg').css({ height: dropdownHeight, opacity: "0.95" });
            }
        });
    }

    //Search and language bar position deppends on layout [Desktop, Tablet, Mobile]
    if ($(window).width() < 1200 && $(window).width() > 767) {
        $( ".search" ).appendTo( ".navbar .container" );
        $( ".language-selector" ).insertAfter( ".top-utility-bar .navbar-brand" );
    } else if ($(window).width() <= 767) {
        $( ".search" ).insertAfter( "#search-icon-dropdown" );
        $( ".language-selector" ).appendTo( ".social" );
    }

    //Mobile menu scroll fix on small screen devices 
    $(".navbar-collapse").css({ maxHeight: $(window).height() - $(".navbar-header").height() + "px" });
    fixBadSidebarBackground();
});

/* on window resize function */
var origWidth = $(window).width();
$(window).on('resize', function() {
    var nowWidth = $(window).width();
    if(origWidth !== nowWidth) { 
        
        heroScrollEffect();
        searchDrop(); //Search dropdown
        closeSearch();
        socialFloat(); //Social icon float in mobile menu
        tabletDropown(); //Dropdowns on Tablet layout
        mobileModal(); //Mobile modal

        /** Tablet dropdown background **/
        if ($(window).width() < 1200) {
            $('.dropdown-bg').addClass('dropdown-bg-tablet');
        } else  {
            $('.dropdown-bg').removeClass('dropdown-bg-tablet');
        }

        //Search dropdown icon change on click
        if ($( '#search-icon-dropdown i' ).hasClass('fa-times')) {
            $("#search-icon-dropdown i").toggleClass("fa-times fa-search");
        }

        //Search and language position deppends on layout [Desktop, Tablet, Mobile]
        if ($(window).width() < 1200 && $(window).width() > 767) {
            $( ".search" ).appendTo( ".navbar .container" );
            $( ".language-selector" ).insertAfter( ".top-utility-bar .navbar-brand" );
        } else if ($(window).width() <= 767) {
            $( ".search" ).insertAfter( "#search-icon-dropdown" );
            $( ".language-selector" ).appendTo( ".social" );
        } else if($(window).width() > 1200){
            $( ".search" ).insertAfter( "#search-icon-dropdown" );
        }

        //Mobile menu scroll fix on small screen devices 
        $(".navbar-collapse").css({ maxHeight: $(window).height() - $(".navbar-header").height() + "px" });
    }
    origWidth = nowWidth;
});

/* scroll to Ratings and Reviews after page load */

// to top right away
if (window.location.hash) {
    window.scrollTo(0, 0);
}
// void some browsers issue
setTimeout(function () { window.scrollTo(0, 0); }, 1);

$(window).on('load', function () {
    if (window.location.hash) {
        // smooth scroll to the anchor id
        $('html, body').animate({
            scrollTop: $(window.location.hash).offset().top - $('#navbar-main').outerHeight() + 7
        }, 1000, 'swing');
    }
});

function fixBadSidebarBackground() {
    // Fix sidebar background issue for product
    if ($('.item-sidebar') 
        && $('.item-sidebar').css('padding-right') === '0px'
        && $('.item-sidebar').height() >= $('.item-main').height()) {
        $('.item-main').height($('.item-sidebar').height())
    } else {
        $('.item-main').height('auto')
    }
    // Fix sidebar background issue for article
    if ($('.article-sidebar') 
        && $('.article-sidebar').css('padding-right') === '0px'
        && $('.article-sidebar').height() >= $('.article-details').height()) {
        $('.article-details').height($('.article-sidebar').height())
    } else {
        $('.article-details').height('auto')
    }
}