function productCollectionStage() {
    //Homepage Product Collection Stage

    var isAnimted = false;

    var clickItem = function () {
        if ($(window).width() > 480 && isAnimted === false && !$(this).hasClass('current')){

            isAnimted = true;

            $('.product-collection-thumbs-slider').addClass('product-thumbs-ani');

            $('.product-collection-thumb-wrapp').removeClass('current');
            $(this).addClass('current');

            var stageItemToMove = $('.stage-item-' + $(this).data('item'));
            var itemToShow = $('.prod-item-' + $(this).data('item'));

            if (itemToShow.hasClass('active')) {
                return false;
            }

            $('.product-collection-slider').find('.active').queue(function (n) {
                $(this).find('.prod-1').removeClass('animated fadeInLeft').addClass('animated fadeOutLeft');
                $(this).find('.prod-2').removeClass('animated fadeInRight').addClass('animated fadeOutRight');
                $('.product-stage-content').fadeOut();
                n();
            }).delay(200).queue(function (n) {
                $(this).removeClass('active');
                n();
            }).delay(250).queue(function (n) {
                itemToShow.fadeIn('slow').addClass('active');
                stageItemToMove.addClass('active');
                stageItemToMove.find('.prod-1').removeClass('animated fadeOutLeft').addClass('animated fadeInLeft');
                stageItemToMove.find('.prod-2').removeClass('animated fadeOutRight').addClass('animated fadeInRight');
                isAnimted = false;
                $('.product-stage-product').one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function (e) {
                    isAnimted = false;
                    $('.product-collection-thumbs-slider').removeClass('product-thumbs-ani');
                });
                n();
            });
        } else {
            $('.product-collection-thumbs-slider').removeClass('product-thumbs-ani');
            console.log('nope');
            return false;
        }
    }

    //console.log(isAnimted);

    $('.product-collection-thumb-wrapp').on('click', clickItem);
    // Undbind And Bind After Swipe
    $('.product-collection-thumbs').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.product-collection-thumb-wrapp').unbind('click', clickItem);
    });
    $('.product-collection-thumbs').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        $('.product-collection-thumb-wrapp').bind('click', clickItem);
    });
}