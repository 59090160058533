//Homepage Product Collection Stage Product Scroll Effect
function productScrollEffect() {
    if ($('.product-collection-slider').length) {
        var wScroll = $(this).scrollTop();
        var t = $('.product-collection-slider').offset().top - ($('.product-collection-slider').outerHeight() / 2) + 15;
        var translateVal = parseInt((wScroll - t) / 4) - 50;
        if (wScroll > t) {
            $('.product-stage-product-float').each(function (index, el) {
                $(this).css({
                    //'transform': 'translate(' + (wScroll/100) + 'px, '+ (wScroll/100) +'%)'
                    'transform': 'translateY(' + translateVal + 'px)'
                });
            });
        } else {
            $('.product-stage-product-float').each(function (index, el) {
                $(this).css({
                    'transform': 'translateY(0px)'
                });
            });
        }
    }
}