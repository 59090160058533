function closeSearch () {
    if($('.search').css('display') == 'block' && $(window).width() < 1200) {
        // hide search element
        if(!$(".main-nav .dropdown").hasClass('open')) {
            $('.search').hide();
        } else {
            $('.search').slideUp(200);
        }
        // reset search icon
        if ($( '#search-icon-dropdown i' ).hasClass('fa-times')) {
            $("#search-icon-dropdown i").toggleClass("fa-times fa-search");
        }
    }
}