//Tablet layout click main nav items
function tabletDropown(){
    if (windowWidth < 1200) {
        $('.navbar-default .navbar-nav li > a').attr('data-toggle', 'dropdown'); //Only for products (temporary) - remove :first-child for all
        $('.navbar-default .social li:first-child > a').attr('data-toggle', '');
        $('.main-nav .dropdown-menu').css('opacity', '1');
        $('.navbar-default .navbar-nav > .open > a').on('click',function(){
            $('.navbar-default .navbar-nav li > a').removeAttr('data-toggle');
        });
    }
}

//tablet and mobile menu fix
if($(window).width() < 1200 && $(window).width() > 767) {
    $(".navbar-default .navbar-nav li > a").on('click', function() {
        var clicked = $(this).attr('aria-expanded');

        if (clicked == 'true') {
            $(".dropdown-menu").css('opacity', '0');
            $(this).attr('data-hover','');
            $(this).attr('data-toggle','');
        }

        if ($(this).hasClass('active') === false) {
            $(this).addClass('active');
            $(".navbar-default .navbar-nav li > a").not($(this)).removeClass('active');
        }
    });
}