jQuery(document).ready(function ($) {

    //videojs('video-id1', {
    //    techOrder: ['youtube'],
    //    sources: [{
    //        type: 'video/mp4',
    //        src: 'http://www.youtube.com/watch?v=xYemnKEKx0c'
    //    }]
    //});

    if (document.getElementById('yt-player') != null) {

        //youtube script
        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        var player;

        var youTubeUrl = $('#yt-player').data('video-url');
        var youTubeId = youTubeUrl.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i);
        if (youTubeId != null) {
            //console.log('video id = ', youTubeId[1]);
        } else {
            console.log('The youtube url is not valid.');
        }
        var videoId = youTubeId[1];

        onYouTubeIframeAPIReady = function (videoId) {
            player = new YT.Player('yt-player', {
                height: '244',
                width: '434',
                //videoId: videoId,  // youtube video id
                playerVars: {
                    'autoplay': 0,
                    'rel': 0,
                    'showinfo': 0
                },
                events: {
                    'onStateChange': onPlayerStateChange
                }
            });
        }

        document.getElementById('yt-player').style.display = 'none';
        //document.getElementById('yt-thumbnail-container').style.backgroundImage = 'url(http://img.youtube.com/vi/' + videoId + '/maxresdefault.jpg)';

        onPlayerStateChange = function (event) {
            if (event.data == YT.PlayerState.ENDED) {
                $('.yt-play-video').fadeIn('normal');
            }
        }

        $(document).on('click', '.yt-play-video', function () {
            $(this).hide();
            $("#yt-player").show();
            $("#yt-thumbnail-container").hide();
            player.playVideo();
            player.loadVideoById(videoId);
        });

    }


});