function Unsubscribe(email, mid, geo, program) {

    if ($('#unsubscribe-radio').is(':checked')) {
        var _jObject = { EMAIL: email, MID: mid, GEO: geo, PROGRAM: program };
        console.log(_jObject);
        $.ajax({
            type: 'POST',
            url: '/OffCommonPages/Unsubscribe',
            data: JSON.stringify(_jObject),
            contentType: "application/json",
            dataType: "json",
            async: false,
            success: function (response) {
                if (response.status === "ok") {
                    $('#content-before-submit').hide();
                    $('#content-after-submit').show();
                }
                else if (response.status === "error") {
                    $('#content-before-submit').hide();
                    $('#content-after-submit-error').show();
                }
                else {
                    $('#content-before-submit').hide();
                    $('#content-after-submit-error').show();
                }
            },
            error: function () {
                $('#content-before-submit').hide();
                $('#content-after-submit-error').show();
                console.log('AJAX Request failed! Please, verify the server is working and your connection to it!');
            }
        });
    }
    else if ($('#staysubscribed-radio').is(':checked')) {
        $('#content-before-submit').hide();
        $('#content-after-submit-stayed').show();
    }
}