//Mobile menu minus plus change on opened submenu
function plusMinus(){
    if ($(window).width() <= 767) { 
        $('.main-nav li').on('show.bs.dropdown', function () {
            $(this).find('i.fa').removeClass('fa-plus').addClass('fa-minus');
        });
        $('.main-nav li').on('hide.bs.dropdown', function () {
            $(this).find('i.fa').removeClass('fa-minus').addClass('fa-plus');
        });
    }
}