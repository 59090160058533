function LoadMoreSearchResults(start, count) {
    var url = "/SearchResults/SearchResultsFound";
    var searchWord = $('#inputSearchWord').attr('attrValue');
    var indexName = $('#inputSearchWord').attr('attrIndex');
    var request = $.ajax({
        url: url,
        type: "Post",
        data: { searchWord : searchWord, indexName : indexName, start: start, count: count },
        dataType: "html",
        success: function (data) {
            $('.navigation-more').remove();
            $('#wrapper').append($.parseHTML(data));
        }
    });

    request.fail(function (jqXHR, textStatus) {
    });
}

$('#searchResultLink').on('click', function(){
    var link = $(this).attr( "data-url" );
    var text = $("#inputSearchWord");
    window.location.href = link + "?searchWord=" + text.val();
});

$('#searchLink').on('click', function(){
    var link = $(this).attr( "data-url" );
    var text = $("#inputSearch");
    window.location.href = link + "?searchWord=" + text.val();
});

$("#inputSearchWord").keypress(function(e) {
    if (e.which == 13) { 
        var a = $("#searchResultLink").data("url"), 
        t = $("#inputSearchWord"); 
        window.location.href = a + "?searchWord=" + t.val(); 
        return false; 
    }
}); 


$("#inputSearch").keypress(function(e) {
    if (e.which == 13) { 
        var a = $("#searchLink").data("url"), 
        t = $("#inputSearch"); 
        window.location.href = a + "?searchWord=" + t.val() 
        return false; 
    }
});