// filter items

// toggle filters wrapp dropdown block (.off-filters-drop) on mobile
$('.off-filters-btn-mobile').on('click', function (e) {
    if ($(this).next('.off-filters-drop').is(':visible')) {
        $(this).removeClass('opened');
    } else {
        $(this).addClass('opened');
    }
    e.preventDefault();
});

var listSelected = {};
var showSelected = [];

// select and show items in filters toggle dropdown block button (.off-filters-btn-selected)
var filterClick = $('.list-group li').children('a').not('.filter-disabled');
filterClick.on('click', function (e) {

    showSelected = [];

    $(this).parents('.product-filter-wrapp').addClass('filter-item-selected');
    var groupId = $(this).parents('.list-group').attr('id');
    var selectedGroupitem = $(this).parent().data('text');

    listSelected[groupId] = selectedGroupitem;
    for (var prop in listSelected) {
        showSelected.push(listSelected[prop]);
    }

    $('.off-filters-btn-selected').html(showSelected.join(', '));

    $(this).parents('ul').prev().html(selectedGroupitem);

    if (showSelected.length != 0) {
        $('.off-filters-btn-mobile').addClass('has-selection');
    }

    $('.off-filters-btn-mobile').removeClass('opened');

    //console.log('add and update applied filter items - ' + showSelected);

    e.preventDefault;
});

// reset filter from the selected group and update filters toggle dropdown block button (.off-filters-btn-selected)
$('.reset-drop-filter').on('click', function (e) {

    showSelected = [];

    var groupItemToRemove = $(this).parents('.product-filter-wrapp');
    var groupId = groupItemToRemove.find('.list-group').attr('id');
    var resetFilterButton = $(this).parent().find('.dropdown-toggle');

    //deleteByVal(groupItemToRemove, listSelected);
    delete listSelected[groupId];

    for (var prop in listSelected) {
        showSelected.push(listSelected[prop]);
    }

    $('.off-filters-btn-selected').html(showSelected.join(', '));

    // if there are no selected items revert the button
    if (showSelected.length == 0) {
        $('.off-filters-btn-selected').html($('.off-filters-btn-selected').data('filter'));
        $('.off-filters-btn-mobile').removeClass('has-selection');
    }

    resetFilterButton.html(resetFilterButton.data('filter') + '<span class="caret fa fa-chevron-down"></span>');
    groupItemToRemove.removeClass('filter-item-selected open');
    groupItemToRemove.find('input:checked').attr('checked', false).trigger('change');

    $('.off-filters-btn-mobile').removeClass('opened');

    e.preventDefault;
    return false;
});

// clear all filter groups and close filters wrapp dropdown block (.off-filters-drop)
$('.off-filters-clear').on('click', function (e) {

    showSelected = [];
    for (var prop in listSelected) {
        delete listSelected[prop];
    }

    $('.off-filters-btn-selected').html($('.off-filters-btn-selected').data('filter'));

    $('.product-filter-wrapp .dropdown-toggle').each(function () {
        $(this).html($(this).data('filter') + '<span class="caret fa fa-chevron-down"></span>');
    });

    $('.product-filter-wrapp').removeClass('filter-item-selected');
    $('.off-filters-btn-mobile').removeClass('opened has-selection');

    $('.list-group').find('input:checked').attr('checked', false).trigger('change');

    $('.off-filters-btn-mobile').removeClass('opened');

    //clearCompare();

    e.preventDefault;
});

$("#compareUrl").click(function (e) {

    var _jObject = { category: "", duration: "", benefit: "", form: "" };
    var selectedCategory = $("input[name='category']:checked").data("value");
    var selectedDuration = $("input[name='duration']:checked").data("value");
    var selectedBenefit = $("input[name='benefit']:checked").data("value");
    var selectedForm = $("input[name='form']:checked").data("value");
    _jObject.category = selectedCategory;
    _jObject.duration = selectedDuration;
    _jObject.benefit = selectedBenefit;
    _jObject.form = selectedForm;

 //   console.log(_jObject);

    $.ajax({
        type: 'POST',
        url: 'OffProducts/SaveFilters',
        data: JSON.stringify(_jObject),
        contentType: "application/json",
        dataType: "json",
        async: false,
        success: function (response) {

        },
        error: function () {

        }
    });

    e.preventDefault;
    return false;

});

//Filter cross categories 

var buttonFilter = {
  
  // Declare any variables we will need as properties of the object
  
  $filters: null,
  $reset: null,
  groups: [],
  outputArray: [],
  outputString: '',
  
  // The "init" method will run on document ready and cache any jQuery objects we will need.
  
  init: function(){
    var self = this; // As a best practice, in each method we will asign "this" to the variable "self" so that it remains scope-agnostic. We will use it to refer to the parent "buttonFilter" object so that we can share methods and properties between all parts of the object.
    
    self.$filters = $('#Filters');
    self.$reset = $('.reset-drop-filter');
    self.$container = $('#Container');
    
    self.$filters.find('.list-group').each(function(){
      self.groups.push({
        $buttons: $(this).find('.filter'),
        active: ''
      });
    });
    
    self.bindHandlers();
  },
  
  // The "bindHandlers" method will listen for whenever a button is clicked. 
  
  bindHandlers: function(){
    var self = this;
    
    // Handle filter clicks
    
    self.$filters.on('click', '.filter', function(e){
      e.preventDefault();
      
      var $button = $(this);
      
      // If the button is active, remove the active class, else make active and deactivate others.
      
      var liLength = $button.parents('ul').find('li .filter');
      
      $button.hasClass('active') ? $button.removeClass('active1') : _removeActive();
      function _removeActive() {
        $.each(liLength, function(index, value){
            $(value).removeClass('active');
        });
        $button.addClass('active');
        $button.parents('.list-group').addClass('active-ul');
      };

      self.parseFilters();
    });
    
    // Handle reset click
    $('.off-filters-clear').on('click', function(e){
      e.preventDefault();
      
      self.$filters.find('.filter').removeClass('active');
      
      self.parseFilters();
    });
    
    self.$reset.on('click', function(e){
      e.preventDefault();
      //var thisElement = $($(this)[0].previousSibling.previousElementSibling); //Native JS 
      var thisElementNew = $($(this).siblings('.list-group')); // jQuery
      //console.info(thisElement.find('.filter'));
      //self.$filters.find('.filter').removeClass('active');
      thisElementNew.find('.filter').removeClass('active');
      $(this).siblings('.list-group').removeClass('active-ul');
      
      self.parseFilters();

    });
  },
  
  // The parseFilters method checks which filters are active in each group:
  
  parseFilters: function(){
    var self = this;
 
    // loop through each filter group and grap the active filter from each one.
    
    for(var i = 0, group; group = self.groups[i]; i++){
      group.active = group.$buttons.filter('.active').attr('data-filter') || '';
    }
    
    self.concatenate();
  },
  
  // The "concatenate" method will crawl through each group, concatenating filters as desired:
  
  concatenate: function(){
    var self = this;
    
    self.outputString = ''; // Reset output string
    
    for(var i = 0, group; group = self.groups[i]; i++){
      self.outputString += group.active;
    }
    
    // If the output string is empty, show all rather than none:
    
    !self.outputString.length && (self.outputString = 'all'); 
    
    //console.log(self.outputString); 
    
    // ^ we can check the console here to take a look at the filter string that is produced
    
    // Send the output string to MixItUp via the 'filter' method:
    
      if(self.$container.mixItUp('isLoaded')){
        self.$container.mixItUp('filter', self.outputString);
      }
  }
};
  
// On document ready, initialise our code.

jQuery(function (){
      
  // Initialize buttonFilter code
      
  buttonFilter.init();
      
  // Instantiate MixItUp
      
  jQuery('#Container').mixItUp({
    controls: {
      enable: false // we won't be needing these
    },
    callbacks: {
        onMixEnd: function(){
          disableFilters();
        }   
    }
  });    
});

function getViableFilters() {
    var visibleItems = $('.collection-products-list-item:visible');

    var filters = [];
    var uuidTest = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

    visibleItems.each(function() {
        var filter = $(this).attr('class').split(' ');
        for(var i = 0; i < filter.length; i++) {
            if(filters.indexOf(filter[i]) < 0 && uuidTest.test(filter[i])) {
                filters.push(filter[i]);
            }
        }
    });

    return filters;
}

function disableFilters() {
    var toRemain = getViableFilters();
    var allFilters = $('a[data-filter]');

    allFilters.addClass('filter-disabled').attr('disabled', true);

    for(var i = 0; i < toRemain.length; i++) {
        $('a[data-filter=".' + toRemain[i] + '"]').removeClass('filter-disabled').removeAttr('disabled');
    }
}