function desktopScroll() {

    $window.scroll(function () {
        if ($(window).width() > 1200) {

            //Navbar Dropdown background
            //if ($window.scrollTop() > distance) {
            //    $('.dropdown-bg').css({'top': '-20px', 'border-top-right-radius': '0', 'border-top-left-radius': '0'});
            //}else {
            //    $('.dropdown-bg').css({'top': '65px', 'border-top-right-radius': '10px', 'border-top-left-radius': '10px'});
            //}

            //Navbar Dropdowns height adjustments on scroll
            /*if ($window.scrollTop() > distance && dropopen_products == true) {
                $('.dropdown-bg').stop().animate({ height: "560px" }, 400);
            }
            else if ($window.scrollTop() < distance && dropopen_products == true) {
                $('.dropdown-bg').stop().animate({ height: "580px" }, 400);
            }*/
            //Inspiration and Education dropdown effect on scroll - remove comment block 
            /*else if ($window.scrollTop() > distance && dropopen_inspiration == true) {
                $('.dropdown-bg').stop().animate({ height: "420px" }, 400);
            }
            else if ($window.scrollTop() < distance && dropopen_inspiration == true) {
                $('.dropdown-bg').stop().animate({ height: "470px" }, 400);
            }  
            else if ($window.scrollTop() > distance && dropopen_education == true) {
                $('.dropdown-bg').stop().animate({ height: "420px" }, 400);
            }
            else if ($window.scrollTop() < distance && dropopen_education == true) {
                $('.dropdown-bg').stop().animate({ height: "470px" }, 400);
            } */

            //On Scroll Effects
            //Hero (Callouts) Image Effect
            heroScrollEffect();
            //Homepage Product Collection Stage Product Scroll Effect
            productScrollEffect();
            //Homepage Articles Collection Card Scroll Effect
            //articleCardScrollEffect();
        }

    });
}