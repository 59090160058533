// show steps/questions
$('.js-pf-show-steps').click(function () {
    //var pfIntroBtn = $(this);
    $('.pf-intro').fadeOut(200, function () {
        $('.pf-wrapp').addClass('pf-steps-opened');
        $('.pf-steps-wrapp').fadeIn(100, function () {
            $('.pf-steps').addClass('pf-steps-visible');
        });
    });

    return false;
});

// reset steps/questions
function resetSteps() {
    $('.pf-steps-group').removeClass('pf-group-visible pf-group-previous');
    $('.pf-steps .pf-steps-group:first').addClass('pf-group-visible');

    $('.pf-progress-bar').css('width', '0');

    $('.pf-steps-wrapp').removeClass('pf-products-list-visible');

}
$('.pf-start-over').click(function () {
    resetSteps();
    return false;
});

// close steps/questions
function closeSteps() {
    $('.pf-steps-wrapp').fadeOut(200, function () {
        $('.pf-wrapp').removeClass('pf-steps-opened');
        $('.pf-intro').fadeIn();
        $('.pf-steps').removeClass('pf-steps-visible');

        resetSteps();

    });
}
$('.js-pf-close-steps').click(function () {
    closeSteps();
    return false;
});

// set transition delay for group children
function setDelay() {
    $('.pf-steps-group').each(function () {
        $(this).find('.pf-group-child').each(function (index) {
            if (index > 0) {
                $(this).css('transition-delay', index / 8 + 's');
            }
        });
    });
}
setDelay();

// skip to
$('.pf-skip-to').click(function () {
    return false;
});

// pf-image hover / mobile

var swapImagesOn = 1024;

$(document).on('mouseenter', '.pf-group-child', function () {
    var $this = $(this).find('.pf-child-image');
    if ($this.attr('data-pf-image-hover') && $(window).width() > swapImagesOn) {
        var newSource = $this.data('pf-image-hover');
        $this.data('pf-image-hover', $this.attr('src'));
        $this.attr('src', newSource);
    } else {
        return false;
    }
}).on('mouseleave', '.pf-group-child', function () {
    var $this = $(this).find('.pf-child-image');
    if ($this.attr('data-pf-image-hover') && $(window).width() > swapImagesOn) {
        var newSource = $this.data('pf-image-hover');
        $this.data('pf-image-hover', $this.attr('src'));
        $this.attr('src', newSource);
    } else {
        return false;
    }
});

$('.pf-group-child').on('click', '.pf-child-image', function () {
    var $this = $(this);
    if ($this.attr('data-pf-image-hover') && $(window).width() > swapImagesOn) {
        var newSource = $this.data('pf-image-hover');
        $this.data('pf-image-hover', $this.attr('src'));
        $this.attr('src', newSource);
    } else {
        return false;
    }
});

function swapOnMobile(el) {
    $(el).each(function () {
        var $this = $(this);
        var desktopSource = $this.data('pf-image-static');
        var mobileSource = $this.data('pf-image-hover');
        if ($(window).width() < swapImagesOn) {
            $this.attr('src', mobileSource);
        } else if ($(window).width() > swapImagesOn) {
            $this.attr('src', desktopSource);
        }
    });
}

function swapGifOnMobile(el) {
    if ($(window).width() < swapImagesOn) {
        $(el).each(function () {
            $(this).attr('src', function (index, attr) {
                return attr.replace('animated', 'static');
            });
        });
    } else {
        $(el).each(function () {
            $(this).attr('src', function (index, attr) {
                return attr.replace('static', 'animated');
            });
        });
    }
}

// pf result slider
function resultSlider() {
    if ($('.pf-products-slider').length && !$('.pf-products-slider').hasClass('slick-initialized')) {

        $('.pf-products-slider-paging').children().remove();
        $('.pf-products-slider-paging-mobile').html('');

        // replace pagging dots with numbers on mobile
        $('.pf-products-slider').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            $('.pf-products-slider-paging-mobile').text(i + '/' + slick.slideCount);
        });

        $('.pf-products-slider').slick({
            dots: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            appendDots: $('.pf-products-slider-paging'),
            prevArrow: $('.slick-custom-prev'),
            nextArrow: $('.slick-custom-next'),
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        centerMode: false,
                        infinite: false
                    }
                },
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        infinite: false
                    }
                }
            ]
        });
        
        checkSlickNoSlide('.pf-products-slider');

    }
}
//resultSlider();

// center slider items if slider not active
function checkSlickNoSlide(slider) {
    if ($('.pf-products-slider').length) {
        var elm = $(slider),
            getSlick = elm.slick('getSlick'),
            slidesToShowDesktop = 4,
            slidesToShowTablet = 3,
            minWidthDesktop = 769; // same as the first brakepoint for slick slider
            minWidthTablet  = 481; // same as the second brakepoint for slick slider

        //if (getSlick.slideCount < slidesToShow & $(window).width() > minWidth) {
        //    elm.addClass('slick-no-slide');
        //} else if (getSlick.slideCount == slidesToShow & $(window).width() > minWidth) { //getSlick.options.slidesToShow
        //    elm.addClass('slick-no-slide slick-no-max');
        //} else {
        //    elm.removeClass('slick-no-slide slick-no-max');
        //    $('.pf-products-slider-paging').find('.slick-dots:not(:last)').remove();
        //}

        if ($(window).width() > minWidthDesktop) {
            if (getSlick.slideCount < slidesToShowDesktop) {
                elm.addClass('slick-no-slide');
            } else if (getSlick.slideCount == slidesToShowDesktop) { //getSlick.options.slidesToShow
                elm.addClass('slick-no-slide slick-no-max');
            } else {
                elm.removeClass('slick-no-slide slick-no-max');
                $('.pf-products-slider-paging').find('.slick-dots:not(:last)').remove();
            }
        } else if ($(window).width() > minWidthTablet) {
            if (getSlick.slideCount < slidesToShowTablet) {
                elm.addClass('slick-no-slide');
            } else if (getSlick.slideCount == slidesToShowTablet) { //getSlick.options.slidesToShow
                elm.addClass('slick-no-slide slick-no-max');
            } else {
                elm.removeClass('slick-no-slide slick-no-max');
                $('.pf-products-slider-paging').find('.slick-dots:not(:last)').remove();
            }
        }


    }
}

$(window).resize(function () {
    checkSlickNoSlide('.pf-products-slider');
    swapOnMobile('.pf-child-image');
    swapGifOnMobile('.pf-child-image-clock');
    if($('.slick-dots').length>1){
        $('.pf-products-slider-paging').find('.slick-dots:not(:last)').remove();
    }
});

// Load Steps
var nextStep = function (e) {

    $.ajax({
        url: '/Home/RenderStep?itemId=' + $(this).attr('attrItemId'),
        contentType: "application/html;charset=utf-8",
        type: "GET",
        dataType: "html",
        success: function (result) {
            $("#renderSteps").find('.pf-group-visible').addClass('pf-group-previous');
            $('.pf-skip-to').fadeIn();
            setTimeout(function () {
                $("#renderSteps").children().remove();
                $('#renderSteps').html(result);
                if ($('.pf-group-child-wrapp > div').length <= 2) {
                    $('.pf-group-child-wrapp > div').addClass('pf-group-child-middle');
                }
                //$('.pf-products-slider-paging').html('');
                $('.pf-products-slider-paging').children().remove();
            }, 250);
            setTimeout(function () {
                $('.pf-steps-group').addClass('pf-group-visible');
            }, 500);
            setTimeout(function () {
                resultSlider();
                setDelay();
                swapOnMobile('.pf-child-image');
                swapGifOnMobile('.pf-child-image-clock');

                $('.pf-progress-bar').css('width', $('#progressButton').attr('attrProgress'));

                $('.pf-start-over').fadeOut(200, function () {
                    if ($('#skipButtonVisible').length == 0) {
                        $('.pf-skip-to').fadeIn(100);
                    }
                });
                $('.pf-progress').fadeIn();

                if ($('#startOverButton').length) {
                    $('.pf-skip-to').fadeOut(200, function () {
                        $('.pf-start-over').fadeIn(100);
                    });
                    $('.pf-steps-controls').addClass('pf-product-list-visible');
                } else {
                    $('.pf-steps-controls').removeClass('pf-product-list-visible');
                }

            }, 260);

        }
    });

    e.stopImmediatePropagation();
    return false;

}
//var stepBack = function (e) {
//    if ($('#backButton').attr('attrParentItemId') == "" || $('#backButton').attr('attrParentItemId') === undefined) {
//        //$('.pf-steps-wrapp').fadeOut(200, function () {
//        //    $('.pf-wrapp').removeClass('pf-steps-opened');
//        //    $('.pf-intro').fadeIn();
//        //    $('.pf-steps').removeClass('pf-steps-visible');
//        //    //$('.pf-steps-group').addClass('pf-group-visible pf-group-previous');
//        //    //$('.pf-steps .pf-steps-group:first').addClass('pf-group-visible');
//        //    $('.pf-steps .pf-steps-group:first').removeClass('pf-group-visible');

//        //    $('.pf-progress-bar').css('width', '0');

//        //});

//        closeSteps();

//        return false;
//    }
//    $.ajax({
//        url: '/Home/RenderStep?itemId=' + $('#backButton').attr('attrParentItemId'),
//        contentType: "application/html;charset=utf-8",
//        type: "GET",
//        dataType: "html",
//        success: function (result) {
//            $("#renderSteps").find('.pf-group-visible').removeClass('pf-group-visible');
//            setTimeout(function () {
//                $("#renderSteps").children().remove();
//                $('#renderSteps').html(result);
//                $('.pf-steps-group').addClass('pf-group-previous');
//                if ($('.pf-group-child-wrapp > div').length <= 2) {
//                    $('.pf-group-child-wrapp > div').addClass('pf-group-child-middle');
//                }
//                //$('.pf-products-slider-paging').html('');
//                $('.pf-products-slider-paging').children().remove();
//            }, 250);
//            setTimeout(function () {
//                $('.pf-steps-group').removeClass('pf-group-previous').addClass('pf-group-visible');
//            }, 500);
//            setTimeout(function () {
//                setDelay();
//                swapOnMobile('.pf-child-image');
//                swapGifOnMobile('.pf-child-image-clock');

//                $('.pf-progress-bar').css('width', $('#progressButton').attr('attrProgress'));

//                $('.pf-start-over').fadeOut(200, function () {
//                    if ($('#skipButtonVisible').length == 0) {
//                        $('.pf-skip-to').fadeIn(100);
//                    }
//                });
//                $('.pf-progress').fadeIn();

//                if ($('#startOverButton').length) {

//                    $('.pf-skip-to').fadeOut(200, function () {
//                        $('.pf-start-over').fadeIn(100);
//                    });
//                    $('.pf-steps-controls').addClass('pf-product-list-visible');
//                } else {
//                    $('.pf-steps-controls').removeClass('pf-product-list-visible');
//                }

//            }, 260);

//        }
//    });

//    e.stopImmediatePropagation();
//    return false;

//}
var stepBack = function (e) {

    if ($('#backButton').attr('attrParentItemId') == "" || $('#backButton').attr('attrParentItemId') === undefined) {
        $('.pf-steps-wrapp').fadeOut(200, function () {

            $('.pf-wrapp').removeClass('pf-steps-opened');
            $('.pf-intro').fadeIn();
            $('.pf-steps').removeClass('pf-steps-visible');
            $('.pf-steps .pf-steps-group:first').removeClass('pf-group-visible');

            $('.pf-progress-bar').css('width', '0');

        });
        return false;
    }
    $.ajax({
        url: '/Home/RenderStep?itemId=' + $('#backButton').attr('attrParentItemId'),
        contentType: "application/html;charset=utf-8",
        type: "GET",
        dataType: "html",
        success: function (result) {
            $("#renderSteps").find('.pf-group-visible').removeClass('pf-group-visible');
            setTimeout(function () {
                $("#renderSteps").children().remove();
                $('#renderSteps').html(result);
                $('.pf-steps-group').addClass('pf-group-previous');
                if ($('.pf-group-child-wrapp > div').length <= 2) {
                    $('.pf-group-child-wrapp > div').addClass('pf-group-child-middle');
                }
                //$('.pf-products-slider-paging').html('');
                $('.pf-products-slider-paging').children().remove();
            }, 250);
            setTimeout(function () {
                $('.pf-steps-group').removeClass('pf-group-previous').addClass('pf-group-visible');
                if ($('.pf-group-visible').data('step-progres') == '0%') {
                    $('.pf-skip-to').fadeOut();
                }
            }, 500);
            setTimeout(function () {
                setDelay();
                swapOnMobile('.pf-child-image');
                swapGifOnMobile('.pf-child-image-clock');

                $('.pf-progress-bar').css('width', $('#progressButton').attr('attrProgress'));

                $('.pf-start-over').fadeOut(200, function () {

                    if ($('#skipButtonVisible').length == 0) {
                        $('.pf-skip-to').fadeIn(100);
                    } else {
                        $('.pf-skip-to').fadeOut(100);
                    }
                });

                $('.pf-progress').fadeIn();

                if ($('#startOverButton').length) {
                    $('.pf-skip-to').fadeOut(10, function () {
                        $('.pf-start-over').fadeIn(100);
                    });
                    $('.pf-steps-controls').addClass('pf-product-list-visible');
                } else {
                    $('.pf-steps-controls').removeClass('pf-product-list-visible');
                    $('.pf-start-over').fadeOut(10, function () {
                        $('.pf-skip-to').fadeOut(100);
                        if ($('#skipButtonVisible').length == 0) {
                            $('.pf-skip-to').fadeIn(100);
                        } else {
                            $('.pf-skip-to').fadeOut(100);
                        }
                    });
                }

            }, 260);

        }
    });

    e.stopImmediatePropagation();
    return false;

}
var skipToResults = function (e) {

    $.ajax({
        url: '/Home/RenderStep?itemId=' + $('#skipButton').attr('attrItemId') + "&skip=1",
        contentType: "application/html;charset=utf-8",
        type: "GET",
        dataType: "html",
        success: function (result) {
            $("#renderSteps").find('.pf-group-visible').addClass('pf-group-previous');
            setTimeout(function () {
                $("#renderSteps").children().remove();
                $('#renderSteps').html(result);
                if ($('.pf-group-child-wrapp > div').length <= 2) {
                    $('.pf-group-child-wrapp > div').addClass('pf-group-child-middle');
                }
                //$('.pf-products-slider-paging').html('');
                $('.pf-products-slider-paging').children().remove();
            }, 250);
            setTimeout(function () {
                $('.pf-steps-group').addClass('pf-group-visible');
            }, 500);
            setTimeout(function () {
                resultSlider();
                setDelay();
                swapOnMobile('.pf-child-image');
                swapGifOnMobile('.pf-child-image-clock');

                $('.pf-start-over').fadeOut(200, function () {
                    $('.pf-skip-to').fadeIn(100);
                });
                $('.pf-progress').fadeIn();

                if ($('#startOverButton').length) {

                    $('.pf-skip-to').fadeOut(200, function () {
                        $('.pf-start-over').fadeIn(100);
                    });
                    //$('.pf-progress').fadeOut();
                    $('.pf-steps-controls').addClass('pf-product-list-visible');
                } else {
                    $('.pf-steps-controls').removeClass('pf-product-list-visible');
                }

            }, 260);
        }
    });

    e.stopImmediatePropagation();
    return false;

}
var startOver = function (e) {

    $.ajax({
        url: '/Home/RenderFirstStep',
        contentType: "application/html;charset=utf-8",
        type: "GET",
        dataType: "html",
        success: function (result) {

            $("#renderSteps").find('.pf-group-visible').removeClass('pf-group-visible');
            setTimeout(function () {
                $("#renderSteps").children().remove();
                $('#renderSteps').html(result);
                $('.pf-steps-group').addClass('pf-group-previous');
                if ($('.pf-group-child-wrapp > div').length <= 2) {
                    $('.pf-group-child-wrapp > div').addClass('pf-group-child-middle');
                }
                //$('.pf-products-slider-paging').html('');
                $('.pf-products-slider-paging').children().remove();
            }, 250);
            setTimeout(function () {
                $('.pf-steps-group').removeClass('pf-group-previous').addClass('pf-group-visible');
            }, 500);
            //$('#renderSteps').html(result);
            //$('.pf-steps-group').addClass('pf-group-visible');
            //resultSlider();
            setTimeout(function () {
                setDelay();
                swapOnMobile('.pf-child-image');
                swapGifOnMobile('.pf-child-image-clock');

                $('.pf-progress-bar').css('width', $('#progressButton').attr('attrProgress'));

                $('.pf-start-over').fadeOut(200, function () {
                    //$('.pf-skip-to').fadeIn(100);
                });
                $('.pf-progress').fadeIn();

                if ($('#startOverButton').length) {
                    $('.pf-skip-to').fadeOut(200, function () {
                        $('.pf-start-over').fadeIn(100);
                    });
                    //$('.pf-progress').fadeOut();
                    $('.pf-steps-controls').addClass('pf-product-list-visible');
                } else {
                    $('.pf-steps-controls').removeClass('pf-product-list-visible');
                }
                //$('.pf-skip-to').hide();
            }, 260);
        }
    });

    e.stopImmediatePropagation();
    return false;

}