// Share
//pinterest
$(".share-pinterest").on("click", function() {
    BazaarVoice.ROISocialActions("1");
});
//facebook
$(".share-facebook").on("click", function() {
    BazaarVoice.ROISocialActions("2"),
        setTimeout(function() {
            window.open($(".share-facebook").attr("data-href"), "popUpWindowFacebook", "width=600px, height=600px");
        }, 200);
});
//twitter
$(".share-twitter").on("click", function() {
    BazaarVoice.ROISocialActions("3"),
        setTimeout(function() {
            window.open($(".share-twitter").attr("data-href"), "popUpWindowTwitter", "width=600px, height=400px");
        }, 200);
});
//email
$(".share-mail").on("click", function() {
    BazaarVoice.ROISocialActions("4");
    window.location.href = "mailto:?subject=" + encodeURIComponent($(this).data("subject")) + "&body=" + encodeURIComponent($(this).data("body"));
});
//print
$(".share-print").on("click", function() {
    BazaarVoice.ROISocialActions("5");
    window.print();
});
//Price spider's where to buy
$("#where-to-buy").on("click", function() {
    BazaarVoice.ROIProductDetailsWhereToBuy();
});

//Default facebook and twitter implementation - but it the asyncronous BV script refuses to fire when popup opens
//$(".share-twitter").on("click", function () {
//    BazaarVoice.ROISocialActions("3");
//    window.open(this.getAttribute("data-href"), 'popUpWindow', 'width=600px, height=600px');
//});
//Asyncronous popup opening after BV has been fired - unfortunately this makes the browser block the popup
//$(".share-twitter").on("click", function () {
//    var link = this.getAttribute("data-href");
//    $.when($.ajax(BazaarVoice.ROISocialActions("3"))).then(function () {
//        window.open(link, 'popUpWindow', 'width=600px, height=600px');
//    });
//});