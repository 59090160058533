//Hero (Callouts) Image Scroll Effect
function heroScrollEffect() {
    var wScroll = $(this).scrollTop();
    $('.hero-plax').each(function(){
        var heroPLax = $(this);
        if (!heroPLax.closest('.page-content-bottom').length) { // exclude the bootom callout/hero
            if (wScroll >= heroPLax.offset().top && wScroll < (heroPLax.offset().top + heroPLax.outerHeight())) {
                var calc = (wScroll - heroPLax.offset().top) / heroPLax.outerHeight();
                heroPLax.find('.hero-image').css({
                    'opacity': (1 - calc).toFixed(1),
                });
            } else if (wScroll < heroPLax.offset().top + $(window).height() - heroPLax.outerHeight() / 2 && wScroll < (heroPLax.offset().top + heroPLax.outerHeight())) {
                var calc = (wScroll) / heroPLax.outerHeight();
                var matrix1 = 1 + calc / 10;
                var matrix2 = 0;
                heroPLax.find('.hero-image').css({
                    'transform': 'matrix(' + matrix1 + ', 0, 0, ' + matrix1 + ', 0, ' + matrix2 + ')',
                    '-ms-transform': 'matrix(' + matrix1 + ', 0, 0, ' + matrix1 + ', 0, ' + matrix2 + ')'
                });
            } else {
                setTimeout(function () {
                    heroPLax.find('.hero-image').css({
                        'opacity': '1',
                    });
                }, 50);
            }
        }
    });
}