//all dropdowns
$('.main-nav .dropdown').on('show.bs.dropdown', function(){
    dropopen = true;
});

$('.main-nav .dropdown').on('hide.bs.dropdown', function(){
    dropopen = false;
});

//products dropdown
$('.main-nav .dropdown:nth-child(1)').on('show.bs.dropdown', function(){
    dropopen_products = true;
});

$('.main-nav .dropdown:nth-child(1)').on('hide.bs.dropdown', function(){
    dropopen_products = false;
});

//inspiration dropdown
$('.main-nav .dropdown:nth-child(2)').on('show.bs.dropdown', function(){
    dropopen_inspiration = true;
});

$('.main-nav .dropdown:nth-child(2)').on('hide.bs.dropdown', function(){
    dropopen_inspiration = false;
});

//education dropdown
$('.main-nav .dropdown:nth-child(3)').on('show.bs.dropdown', function(){
    dropopen_education = true;
});

$('.main-nav .dropdown:nth-child(3)').on('hide.bs.dropdown', function(){
    dropopen_education = false;
});