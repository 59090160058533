var checkInit = false;

$( document ).ready(function() {

    // compare bar sticky
    setTimeout(function () {
        // fix compare bar
        if ($('.compare-bar-sticky').length) {
            $('.compare-bar-sticky').affix({
                offset: {
                    top: $('.product-filters').offset().top + $('.product-filters').outerHeight() - 60
                }
            });
        }
    }, 500);

    $('.compare-item-label-text').on('click', function () {
        var checkedProductsLength = $('.select-to-compare input[type="checkbox"]:checked').length;
        console.log(checkedProductsLength);
        if (checkedProductsLength < 4) {
            $(this).parent().find('input[type="checkbox"]').prop('checked', true).change();
        }
    });

    // Show/Hide Compare bar + //Checkbox text change
    var checkedProductsLength = $('.select-to-compare input[type="checkbox"]:checked').length;

    var resetSelItem = $(this);
    var resetSelLabelText = resetSelItem.parent().find('.compare-item-label-text');
    resetSelLabelText.removeClass('compare-item-label-selected');

    if(checkInit){
        toggleSelectedText($(this));
        toggleSelectedText($('input[type=checkbox]:checked'));
    }
    
    // on load - if no items are selected
    if (checkedProductsLength == 0) {
        checkInit = false;
        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        labelText.removeClass('compare-item-label-selected');
        toggleSelectedText(selItem);

        $('.compare-bar-wrapp').addClass('hidden');
        $('.collection-products-browse-options').removeClass('compare-bar-opend');
        $('.compare-h').removeClass('a-disabled');

    }

    // on load - if more than one item had been previously selected - show the compare bar and disable the compare button in the bar
    if (checkedProductsLength == 1) {
        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        selItem.parent().find('.compare-item-label-text').show();
        selItem.parent().find('.compare-item-label-compare-now').hide();
        labelText.addClass('compare-item-label-selected');

        $('.compare-bar-wrapp').removeClass('hidden');
        $('.collection-products-browse-options').addClass('compare-bar-opend');
        $('.compare-h').addClass('a-disabled');

    }
    // on load - if more than one item had been previously selected - show the compare bar and enable the compare button in the bar
    else if (!checkInit && checkedProductsLength >= 2) {
        checkInit = true;
    }

    if (checkInit && checkedProductsLength == 1) {
        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        labelText.addClass('compare-item-label-selected');
        selItem.parent().find('.compare-item-label-text').show();
        selItem.parent().find('.compare-item-label-compare-now').hide();
    }

    // on load - if two or more items had been previously selected - show the compare bar
    if (checkedProductsLength >= 2) {
        var selItem = $('input[type=checkbox]:checked');
        //var label = selItem.parent().find('label')
        var labelText = selItem.parent().find('.compare-item-label-text');
        //label.attr('for', '');
        labelText.removeClass('compare-item-label-selected');
        toggleSelectedText(selItem);

        $('.compare-bar-wrapp').removeClass('hidden');
        $('.collection-products-browse-options').addClass('compare-bar-opend');
        $('.compare-h').removeClass('a-disabled');

    }

    //

        //Selected product id
        var selectedProductId = $('.select-to-compare input[type="checkbox"]:checked').data("productid");
        //Selected product id array
        var productsIdArray = $('.select-to-compare input[type="checkbox"]:checked').map(function () {
            return $(this).data("productid");
        }).get();
        //Selected product image src array
        var productsPictureArray = $('.select-to-compare input[type="checkbox"]:checked').map(function () {
            return $(this).data("image-url");
        }).get();
        //If checkbox is checked
        var checked = $('.select-to-compare input').is(':checked');
        //Selected product adding
        for ( var i = 0, l = checkedProductsLength; i < l; i++ ) {
            var selectedProductAdd = '<div class="selected-compare-item"><a class="selected-compare-remove" href="javascript:void(0)" title="remove"><i class="fa fa-times"></i></a><div class="selected-compare-item-thumb"><img src=' + productsPictureArray[i] + ' alt="selected item" data-productId=' + productsIdArray[i] + ' /></div></div>'
            //Selected product adding HTML structure
            $('.compare-bar-selected-items').append(selectedProductAdd);
        }
        if (!checked) {
            $('.selected-compare-item-thumb img[data-productId=' + selectedProductId + ']').parents('.selected-compare-item').remove();
        }

        //Limit max 4 products select
        var bol = $("input[type=checkbox]:checked").length >= 4;
        $("input[type=checkbox]").not(":checked").attr("disabled",bol);

        //tooltip popup notification when four products ar selected for compare
        if (bol) {
            $("input[type=checkbox]").not(":checked").parents('.select-to-compare').tooltip();
        } else {
            $('.select-to-compare').tooltip('destroy');
        }

});

$('.select-to-compare input').on('change', function () {

    // Show/Hide Compare bar + //Checkbox text change
    var checkedProductsLength = $('.select-to-compare input[type="checkbox"]:checked').length;

    var resetSelItem = $(this);
    var resetSelLabelText = resetSelItem.parent().find('.compare-item-label-text');
    resetSelLabelText.removeClass('compare-item-label-selected');

    if(checkInit){
        toggleSelectedText($(this));
        toggleSelectedText($('input[type=checkbox]:checked'));
    }

    if (checkedProductsLength == 0) {
        checkInit = false;

        $('.compare-bar-wrapp').addClass('hidden');
        $('.collection-products-browse-options').removeClass('compare-bar-opend');
        $('.compare-h').removeClass('a-disabled');
    }

    if (checkedProductsLength == 1) {
        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        labelText.addClass('compare-item-label-selected');

        $('.compare-bar-wrapp').removeClass('hidden');
        $('.collection-products-browse-options').addClass('compare-bar-opend');
        $('.compare-h').addClass('a-disabled');

    } else if (!checkInit && checkedProductsLength >= 2) {
        checkInit = true;
        toggleSelectedText($('input[type=checkbox]:checked')); //Change text of checboxes already selected (when user select two products)

        $('.compare-h').removeClass('a-disabled');

    }

    if(checkInit && checkedProductsLength == 1){
        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        labelText.addClass('compare-item-label-selected');

        selItem.parent().find('.compare-item-label-text').show();
        selItem.parent().find('.compare-item-label-compare-now').hide();

    }

    if (checkedProductsLength >= 2) {
        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        labelText.removeClass('compare-item-label-selected');
        toggleSelectedText(selItem);
        
        $('.compare-h').removeClass('a-disabled');

    }

    //

        //Limit max 4 products select
        var bol = $("input[type=checkbox]:checked").length >= 4;   
        $("input[type=checkbox]").not(":checked").attr("disabled",bol);

        //tooltip popup notification when four products ar selected for compare
        if (bol) {
            $("input[type=checkbox]").not(":checked").parents('.select-to-compare').tooltip();
        } else {
            $('.select-to-compare').tooltip('destroy');
        }

        //Selected product id
        var selectedProductId = $(this).data("productid");
        //Selected product image url path
        var selectedProductImage = $(this).data("image-url");
        //If checkbox is checked
        var checked = $(this).is(':checked');
        //Selected product adding HTML structure
        var selectedProductAdd = '<div class="selected-compare-item"><a class="selected-compare-remove" href="javascript:void(0)" title="remove"><i class="fa fa-times"></i></a><div class="selected-compare-item-thumb"><img src=' + selectedProductImage + ' alt="selected item" data-productId=' + selectedProductId + ' /></div></div>'
        //Selected product adding
        $('.compare-bar-selected-items').append(selectedProductAdd);
        if (!checked) {
            $('.selected-compare-item-thumb img[data-productId=' + selectedProductId + ']').parents('.selected-compare-item').remove();
        }

});

$('.select-to-compare input').each(function () {
    toggleSelectedText($(this));
});

// toggle selected items labels
function toggleSelectedText(selItem) {
    if (selItem.is(':checked')) {
        selItem.parent().find('.compare-item-label-text').hide();
        selItem.parent().find('.compare-item-label-compare-now').show();
    } else {
        selItem.parent().find('.compare-item-label-text').show();
        selItem.parent().find('.compare-item-label-compare-now').hide();
    }
}

//Selected product removing from compare bar
$('body').on('click', '.selected-compare-remove', function () {

    var compareBarProductId = $(this).next().children().data("productid");
    $(this).parent().remove();
    $('input[data-productId=' + compareBarProductId + ']').removeAttr('checked');

    //Checkbox text change
    toggleSelectedText($('input[data-productId=' + compareBarProductId + ']'));

    //Show/Hide Compare bar + //Checkbox text change
    var checkedProductsLength = $('.select-to-compare input[type="checkbox"]:checked').length;

    if (checkInit) {
        toggleSelectedText($(this));
        toggleSelectedText($('input[type=checkbox]:checked'));
    }

    if (checkedProductsLength == 0) {
        checkInit = false;

        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        labelText.removeClass('compare-item-label-selected');

        $('input[data-productId=' + compareBarProductId + ']').parent().find('.compare-item-label-text').removeClass('compare-item-label-selected');

        $('.compare-bar-wrapp').addClass('hidden');
        $('.collection-products-browse-options').removeClass('compare-bar-opend');
        $('.compare-h').removeClass('a-disabled');

    }

    if (checkedProductsLength == 1) {
        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        labelText.addClass('compare-item-label-selected');

        $('.compare-bar-wrapp').removeClass('hidden');
        $('.collection-products-browse-options').addClass('compare-bar-opend');
        $('.compare-h').addClass('a-disabled');

    } else if (!checkInit && checkedProductsLength >= 2) {
        checkInit = true;
        toggleSelectedText($('input[type=checkbox]:checked')); //Change text of checboxes already selected (when user select two products)

        $('.compare-h').removeClass('a-disabled');

    }

    if (checkInit && checkedProductsLength == 1) {
        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        labelText.addClass('compare-item-label-selected');

        selItem.parent().find('.compare-item-label-text').show();
        selItem.parent().find('.compare-item-label-compare-now').hide();

    }
    
    if(checkedProductsLength >= 2){
        $('.compare-h').removeClass('a-disabled');
    }

    if (checkedProductsLength >= 2) {
        var selItem = $('input[type=checkbox]:checked');
        var labelText = selItem.parent().find('.compare-item-label-text');
        labelText.removeClass('compare-item-label-selected');
        toggleSelectedText(selItem);
        
        $('.compare-h').removeClass('a-disabled');

    }

    //

        RemoveProductForCompare(compareBarProductId);

        //Limit max 4 products select
        var bol = $("input[type=checkbox]:checked").length >= 4;
        $("input[type=checkbox]").not(":checked").attr("disabled",bol);

});

//Clear all
$('.compare-clear').on('click', function () {
    clearCompare();
    return false;
});

function clearCompare() {
    $('input[type=checkbox]').removeAttr('checked').attr('disabled', false).parent().find('.compare-item-label-text').removeClass('compare-item-label-selected');
    $('.compare-bar-wrapp').addClass('hidden');
    $('.collection-products-browse-options').removeClass('compare-bar-opend');
    $('.compare-bar-selected-items').empty();

    //Checkbox text change
    toggleSelectedText($('input[type=checkbox]'));

    //Destroy tooltip
    $('.select-to-compare').tooltip('destroy');

    var checkInit = false;
    $.ajax({
        type: 'POST',
        url: '/OffProducts/ClearFilters',
        contentType: "application/json",
        dataType: "json",
        async: false,
        success: function (response) {
        },
        error: function () {
        }
    });
}

// remove item from the compare page (item is selected by data-product-id)
$('.remove-item').on('click', function (e) {
    e.preventDefault();
    var productToRemove = $(this).parents('[data-product-id]').data('product-id');
    if ($('.remove-item').length <= 2) { // remove no more than two
        $('.remove-item').addClass('hidden');
        return false;
    } else {
        $("[data-product-id='" + productToRemove + "']").fadeOut(300, function () {
            $(this).remove();
            if ($('.remove-item').length <= 2) {
                $('.remove-item').addClass('hidden');
            }
        });
    }

    var productToRemoveForSession = $(this).parents('[data-product-sitecore-id]').data('product-sitecore-id');
    RemoveProductForCompare(productToRemoveForSession);
});

// on load - hide option to remove item if only two had been selected
if ($('.remove-item').length > 2) {
    $('.remove-item').removeClass('hidden');
}

var durationSelectedFilter;
//Duration filter selected
$('#group-1 li a').on('click', function () {
    durationSelectedFilter = $(this).data('filter');
});

var formSelectedFilter;
//Form filter selected
$('#group-2 li a').on('click', function () {
    formSelectedFilter = $(this).data('filter');
});

var benefitSelectedFilter;
//Benefits filter selected
$('#group-3 li a').on('click', function () {
    benefitSelectedFilter = $(this).data('filter');
});

var collectionSelectedFilter;
//Collection filter selected
$('#group-4 li a').on('click', function () {
    collectionSelectedFilter = $(this).data('filter');
});

function compare(url) {
    var _jObject = { category: collectionSelectedFilter, duration: durationSelectedFilter, benefit: benefitSelectedFilter, form: formSelectedFilter };

    //console.log(_jObject);

    $.ajax({
        type: 'POST',
        url: '/OffProducts/SaveFilters',
        data: JSON.stringify(_jObject),
        contentType: "application/json",
        dataType: "json",
        async: false,
        success: function (response) {

        },
        error: function () {

        }
    });
    window.location = url;
}
$("#compareUrl").click(function (e) {
    
  //  e.preventDefault;
    //return false;

});

$(".product").change(function () {
    var selectedProductID = $(this).data("productid");
    //console.log($(this).data("productid"));
    var selectedProductImage = $(this).data("image-url");
    var _jObject = { productID: selectedProductID };
   // console.log(_jObject);
    if (this.checked) {
        AddProductForCompare(selectedProductID);
    }
    else {
        RemoveProductForCompare(selectedProductID);
    }
});

function RemoveProductForCompare(selectedProductID)
{
    var _jObject = { productID: selectedProductID };
    $.ajax({
        type: 'POST',
        url: '/OffProducts/RemoveProductForCompare',
        data: JSON.stringify(_jObject),
        contentType: "application/json",
        dataType: "json",
        async: false,
        success: function (response) {
        },
        error: function () {
        }
    });
}

function AddProductForCompare(selectedProductID) {
    var _jObject = { productID: selectedProductID };
    $.ajax({
        type: 'POST',
        url: '/OffProducts/AddProductForCompare',
        data: JSON.stringify(_jObject),
        contentType: "application/json",
        dataType: "json",
        async: false,
        success: function (response) {
        },
        error: function () {

        }
    });
}
