//Search dropdown
function searchDrop() {
    $('#search-icon-dropdown').click(function() { //dropdown on click
        if ($(window).width() > 767 && $(window).width() < 1200) {
            if($('.search').css('display') == 'none') {
                $('.search').slideDown(300);
                $('.dropdown-bg').stop(true, false).animate({ height: "120px", opacity: "0.95" }, 300);
                $('.dropdown').removeClass('open');
                if ($( '#search-icon-dropdown i' ).hasClass('fa-search')) {
                     $("#search-icon-dropdown i").toggleClass("fa-search fa-times");
                }
            } else {
                closeSearch();
                $('.dropdown-bg').stop(true, false).animate({ height: "0px", opacity: "0" }, 300);
            }
        }
    });
    $('html').click(function(e) { //dropdown close
        if ($(window).width() > 767 && $(window).width() < 1200) {
            if(!jQuery(e.target).is('.main-nav-wrapp') && !jQuery(e.target).closest('.navbar-main').length) {
                closeSearch();
                $('.dropdown-bg').stop(true, false).animate({ height: "0px", opacity: "0" }, 300);
            }
        }
    });
}