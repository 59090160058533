//Homepage Articles Collection Card Scroll Effect

// commented out in desktopScroll.js by client request

function articleCardScrollEffect() {
    if ($('.articles-collection-slider').length) {
        var wScroll = $(this).scrollTop();
        if (wScroll > $('.articles-collection-wrapp').offset().top - $('.articles-collection-wrapp').outerHeight()) {
            $('.article-collection-slider-card').each(function (index, el) {
                $(this).addClass('float-up-card');
            });
        }
    }
}