//Image popup disable on mobile
function mobileModal() {
  if ($(window).width() < 768) {
    $('.section-text img').removeAttr("data-toggle");
  }
  else{
    $('.section-text img').attr('data-toggle', 'modal');
  }
}

//Slider popup
$('#slider_modal1').on('shown.bs.modal', function (e) {
    $('.article-slider').slick("setPosition", 0);
    $('.article-slider').addClass('article-slider-vis').delay(800);
});
