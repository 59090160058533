var BazaarVoice = function() {
    //private
    var productIds = [],
        ROISocialType,
        ROISocialId;

    renderInlineRatings = function(containerPrefix) {
        if (typeof $BV != 'undefined') {
            $BV.ui('rr', 'inline_ratings', {
                productIds: productIds,
                containerPrefix: containerPrefix
            });
        }
    };

    renderProductReviews = function(productId) {
        if (typeof $BV != 'undefined') {
            $BV.ui("rr", "show_reviews", { productId: productId });
        }
    };

    addProduct = function(productId) {
        productIds.push(productId);
    };

    configureGlobal = function() {
        if (typeof $BV != 'undefined')
            $BV.configure('global', {});
    }

    configureForProduct = function(productId) {
        if (typeof $BV != 'undefined')
            $BV.configure('global', { productId: productId });
    };

    ROISocialActionPreconfiguration = function(type, id) {
        ROISocialType = type;
        ROISocialId = id;
    };

    //TODO: Add this on click of "Where to buy"
    ROIWhereToBuy = function(productId) {
        sendGeneralROI("StoreLocator", "ProductID", productId);
    };

    //On product details we first invoke SocialActionPreconfiguration with the product Id, which is then stored in the ROISocialId property
    ROIProductDetailsWhereToBuy = function() {
        sendGeneralROI("StoreLocator", "ProductID", ROISocialId);
    }

//TODO: Add this where social media share is executed - Inside on click.
    ROISocialActions = function (socialAction) {
        sendGeneralROI("Share", "ProductSocialAction", ROISocialId + " " + socialAction);
    };

    //private
    var sendGeneralROI = function(type, label, value) {
        if (typeof $BV != 'undefined') {
            $BV.SI.trackConversion({
                "type": type,
                "label": label,
                "value": value
            });
        }
    };

    return {
        configureForProduct: configureForProduct,
        configureGlobal: configureGlobal,
        addProduct: addProduct,
        renderProductReviews: renderProductReviews,
        renderInlineRatings: renderInlineRatings,
        ROISocialActionPreconfiguration: ROISocialActionPreconfiguration,
        ROIWhereToBuy: ROIWhereToBuy,
        ROISocialActions: ROISocialActions,
        ROIProductDetailsWhereToBuy: ROIProductDetailsWhereToBuy
        //TODO: Used for debugging purposes. Remove the revealed products after development is done!!!
        //products: productIds
    };
}();